import React from "react";
import { FormattedMessage } from "react-intl";

const MatchInfoGroupPhase = ({ match }) => {
  const { HomeTeam, AwayTeam, HomeTeamScore, AwayTeamScore } = match;

  return (
    <>
      <div>
        <a href={`#${HomeTeam}`} onClick={e => filterSchedule(e, HomeTeam)}>
          <FormattedMessage
            id={`wc2022_${HomeTeam.toLowerCase().split(" ")[0]}`}
          />
        </a>
        <span className="vs"> vs </span>
        <a href={`#${AwayTeam}`} onClick={e => filterSchedule(e, AwayTeam)}>
          <FormattedMessage
            id={`wc2022_${AwayTeam.toLowerCase().split(" ")[0]}`}
          />
        </a>
      </div>
      {HomeTeamScore !== "-" && (
        <span className="score">
          {HomeTeamScore} : {AwayTeamScore}
        </span>
      )}
    </>
  );
};

export default MatchInfoGroupPhase;
