import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";

const YouPicked = styled.div`
  font-size: 1rem;
  .gray {
    opacity: 0.4;
  }
`;
const PointsToBeAdded = styled.div`
  font-size: 0.9rem;
  margin-top: 1rem;
  color: red;
`;

const MatchInfoKnockoutPhase = ({ match, prediction }) => {
  const {
    MatchNumber,
    HomeTeam,
    AwayTeam,
    HomeTeamScore,
    AwayTeamScore,
    CorrectHomeCountry,
    CorrectAwayCountry,
  } = match;
  const {
    homeCountry,
    pointsHomeCountry,
    awayCountry,
    pointsAwayCountry,
    pointsTotal,
  } = prediction;

  return homeCountry ? (
    <div>
      <div>
        {CorrectHomeCountry ? (
          <FormattedMessage
            id={CorrectHomeCountry.toLowerCase().split(" ")[0]}
          />
        ) : (
          <FormattedMessage
            id={`wc2022_${HomeTeam.toLowerCase().split(" ")[0]}`}
          />
        )}
        <YouPicked>
          <FormattedMessage id={homeCountry} />
          {typeof pointsHomeCountry !== "undefined" && (
            <span className={!pointsTotal ? "gray" : ""}>
              → {pointsHomeCountry} <FormattedMessage id="wc2022_points" />
            </span>
          )}
        </YouPicked>
      </div>

      <span className="vs"> vs </span>

      <div>
        {CorrectAwayCountry ? (
          <FormattedMessage
            id={CorrectAwayCountry.toLowerCase().split(" ")[0]}
          />
        ) : (
          <FormattedMessage
            id={`wc2022_${AwayTeam.toLowerCase().split(" ")[0]}`}
          />
        )}
        <YouPicked>
          <FormattedMessage id={awayCountry} />
          {typeof pointsAwayCountry !== "undefined" && (
            <span className={!pointsTotal ? "gray" : ""}>
              → {pointsAwayCountry} <FormattedMessage id="wc2022_points" />
            </span>
          )}
        </YouPicked>
      </div>
      {typeof pointsTotal === "undefined" &&
        typeof pointsHomeCountry !== "undefined" && (
          <PointsToBeAdded>
            <FormattedMessage id="wc2022_points_to_be_counted_later" />
          </PointsToBeAdded>
        )}
      {HomeTeamScore !== "-" && (
        <span className="score">
          {HomeTeamScore} : {AwayTeamScore}
        </span>
      )}
    </div>
  ) : (
    ""
  );
};

export default MatchInfoKnockoutPhase;
