import { navigate } from "gatsby";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Match from "../../pages/worldcup2022/match";
import MatchCardGroupPhase from "./match-info-group-phase";
import MatchCardKnockoutPhase from "./match-info-ko-phase";
import StyledMatch from "./styled-match";

const ScheduleMatch = ({ match, prediction, primaryView, location }) => {
  const intl = useIntl();
  const {
    MatchNumber,
    RoundNumber,
    DateUtc,
    Group,
    HomeTeam,
    AwayTeam,
    HomeTeamScore,
    AwayTeamScore,
    CorrectAnswer,
    stats: { homeWins, awayWins, draws, yes, no } = {},
  } = match;

  const filterSchedule = (e, filter) => {
    e.preventDefault();
    e.stopPropagation();
    window.scrollTo(0, 0);
    navigate(`${location.pathname}#${filter}`);
  };

  const openFullMatch = e => {
    e.preventDefault();
    window.scrollTo(0, 0);
    navigate(`/worldcup2022/match#${MatchNumber}`);
  };

  return (
    <>
      <StyledMatch
        key={MatchNumber}
        primaryview={primaryView}
        HomeTeamScore={HomeTeamScore}
        onClick={openFullMatch}
      >
        <div className="matchinfo">
          <div
            className="matchnumber"
            title={`${intl.formatMessage({
              id: "wc2022_this_is_matchnumber",
            })}`}
          >
            {MatchNumber}
          </div>
          {!!DateUtc && (
            <div>
              {intl.formatDate(new Date(DateUtc * 1000), {
                month: "numeric",
                day: "numeric",
              })}{" "}
              <FormattedMessage id="wc2022_gametime_at" />{" "}
              {intl.formatTime(new Date(DateUtc * 1000))}
            </div>
          )}
          {RoundNumber < 4 && (
            <div className="group">
              <a href={`#${Group}`} onClick={e => filterSchedule(e, Group)}>
                <FormattedMessage id="wc2022_group" /> {Group}
              </a>
            </div>
          )}
        </div>

        {/* Points */}
        {prediction && !isNaN(prediction.pointsTotal) && (
          <div className="points-scored">
            <span>{prediction.pointsTotal}</span>
            <FormattedMessage id="wc2022_points" />
          </div>
        )}

        <div className="match">
          {RoundNumber < 8 ? (
            <>
              {RoundNumber < 4 ? (
                <MatchCardGroupPhase match={match} />
              ) : (
                <MatchCardKnockoutPhase match={match} prediction={prediction} />
              )}

              <div className="prediction">
                {prediction && (
                  <div>
                    <FormattedMessage
                      id={primaryView ? "wc2022_you_predicted" : "wc2022_score"}
                    />
                    {": "}
                    <span className="score">
                      {prediction.homePrediction} : {prediction.awayPrediction}
                    </span>
                    {typeof prediction.pointsResult !== "undefined" ? (
                      <span>
                        {" "}
                        →{" "}
                        {`${
                          prediction.pointsResult + prediction.pointsScore
                        } ${intl.formatMessage({ id: "wc2022_points" })}`}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                )}
                <div className="stats">
                  <span>{`${parseFloat(homeWins).toFixed(
                    1
                  )}% ${intl.formatMessage({
                    id: "wc2022_predicted_a_win_for",
                  })} ${intl.formatMessage({
                    id: `wc2022_${HomeTeam.toLowerCase().split(" ")[0]}`,
                  })}. `}</span>
                  <span>{`${parseFloat(awayWins).toFixed(
                    1
                  )}% ${intl.formatMessage({
                    id: "wc2022_predicted_a_win_for",
                  })} ${intl.formatMessage({
                    id: `wc2022_${AwayTeam.toLowerCase().split(" ")[0]}`,
                  })}. `}</span>
                  <span>{`${parseFloat(draws).toFixed(1)}% ${intl.formatMessage(
                    {
                      id: "wc2022_predicted_a_tie",
                    }
                  )}`}</span>
                </div>
              </div>

              <div className="prediction">
                <span>
                  <FormattedMessage id={`wc2022_q${MatchNumber}`} />
                </span>
                {prediction && (
                  <div className="question">
                    <FormattedMessage
                      id={primaryView ? "wc2022_you_said" : "wc2022_answer"}
                    />
                    {": "}
                    <span className={prediction.answer}>
                      <FormattedMessage id={prediction.answer || "-"} />
                    </span>
                    .
                    {CorrectAnswer && (
                      <span>
                        {" "}
                        <FormattedMessage id="wc2022_the_answer_was" />{" "}
                        <span className={CorrectAnswer}>
                          <FormattedMessage id={CorrectAnswer || "-"} />
                        </span>{" "}
                        → {prediction.pointsAnswer}{" "}
                        {intl.formatMessage({ id: "wc2022_points" })}
                      </span>
                    )}
                  </div>
                )}
                <div className="stats">
                  <p>
                    <span className="yes" style={{ width: `${yes}%` }}>
                      {parseFloat(yes).toFixed(1)}%
                    </span>
                    <span className="no" style={{ width: `${no}%` }}>
                      {parseFloat(no).toFixed(1)}%
                    </span>
                  </p>
                </div>
              </div>
            </>
          ) : (
            // bonus questions
            <>
              <div></div>
              <div className="prediction">
                <span>
                  <FormattedMessage id={`wc2022_q${MatchNumber}`} />
                </span>
                {CorrectAnswer && (
                  <h3>
                    <FormattedMessage id={CorrectAnswer} />
                  </h3>
                )}
                <FormattedMessage
                  id={primaryView ? "wc2022_you_said" : "wc2022_answer"}
                />
                {": "}
                <strong>
                  {MatchNumber === 65 && prediction.answer
                    ? intl.formatMessage({ id: prediction.answer })
                    : prediction.answer}
                </strong>
                {typeof prediction.pointsAnswer !== "undefined" && (
                  <span>
                    {" "}
                    → {prediction.pointsAnswer}{" "}
                    <FormattedMessage id="wc2022_points" />
                  </span>
                )}
              </div>
            </>
          )}
        </div>
      </StyledMatch>
    </>
  );
};

export default ScheduleMatch;
