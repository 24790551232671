import styled from "styled-components";

const StyledResults = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  @media print {
    gap: 10px;
  }

  > h4 {
    flex-basis: 100%;
    padding: 0.5rem;
    background-color: #081782;
    color: white;
    border-bottom: 2px outset #333;
    margin: 1rem 0 -1rem 0;
    border-radius: 0.3rem;
    box-shadow: 2px 2px 4px #000;
    @media print {
      box-shadow: none;
      border-bottom: 1px dashed #000;
      color: #000;
    }
  }
  > div {
    min-width: 250px;
    @media print {
      min-width: 200px;
    }
  }
`;

export default StyledResults;
