import { doc, onSnapshot } from "firebase/firestore";
import { graphql, Link, navigate } from "gatsby";
import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";

import { FirebaseContext } from "../../components/Firebase";
import LoginFirst from "../../components/loginfirst";
import Seo from "../../components/seo";
import { ContentBg, ContentBox } from "../../components/ui";
import ScheduleMatch from "../../components/wc2022/schedule-match";
import StyledResults from "../../components/wc2022/styled-results";

/**
 *
 * NOTE THAT THIS IS THE VERSION _AFTER_ THE WC HAS STARTED!
 * THE VERSION TO FILL OUT THE SCORED IS NOW AT schedule-pre-wc.js
 *
 */

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media print {
    display: none;
  }
  h1 {
    margin: 0;
  }
  a {
    display: flex;
    text-decoration: none;
    margin-left: 1rem;
    font-size: 1.2rem;
    background: #ddd;
    border-radius: 50%;
    width: 2.3rem;
    height: 2.3rem;
    line-height: 2.3rem;
    justify-content: space-around;
    &:hover {
      background: #ccc;
    }
  }
`;

const PointsScored = styled.div`
  background: orangered;
  padding: 0 0.5rem 0.3rem;
  border-radius: 5px;
  box-shadow: 1px 2px 3px #ccc;
  color: white;
  text-shadow: 0 1px 2px #333;
  span {
    font-family: "Courier";
    display: block;
    text-align: center;
    font-size: 2rem;
  }
`;

const ProfileSection = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  img {
    margin: 0 1rem 0 0;
    border-radius: 10px;
    box-shadow: 1px 1px 3px orange;
    width: 5rem;
  }
  /* @media screen and (max-width: 768px) {
    flex-direction: column;
  } */
`;

const Schedule = props => {
  const { location } = props;
  const { firebase, user } = useContext(FirebaseContext);
  const [schedule, setSchedule] = useState([]);
  const [predictions, setPredictions] = useState([]);
  const [profile, setProfile] = useState({});
  const [showOldMatches, setShowOldMatches] = useState(false);
  const [firebaseLoaded, setFirebaseLoaded] = useState(false);

  const primaryView =
    !location.search ||
    (location.search && user && location.search.substring(1) === user.userId);
  const profileUid = primaryView
    ? user && user.userId
    : location.search.substring(1);

  const intl = useIntl();

  useEffect(() => {
    if (firebase && user && !firebaseLoaded) {
      setFirebaseLoaded(true);
      // fetch predictions once, regardless of own vs other's profile:
      firebase.getSchedule().then(schedule => setSchedule(schedule));

      firebase.getProfileByUid(profileUid).then(profile => {
        setProfile(profile);
        firebase.getProfilePredictions(profile.email).then(predictions => {
          setPredictions(predictions);
        });
      });
    }
  });

  // this hackery is done to re-render this component when you switch URLs from secondary to primary
  // at which point it doesn't call useEffect. In that case we'll want to refetch.
  if (
    firebase &&
    location.search.substring(1).length > 0 &&
    profile.userId !== location.search.substring(1)
  ) {
    firebase.getProfileByUid(location.search.substring(1)).then(profile => {
      setProfile(profile);
      firebase.getProfilePredictions(profile.email).then(predictions => {
        setPredictions(predictions);
      });
    });
  }

  // const schedule = data.allSchedule.edges;
  const filter = location.hash
    ? decodeURIComponent(location.hash.substring(1))
    : null;

  let currentRound = 0;
  const yesterday = Date.now() / 1000 - 86400;

  return (
    <ContentBg
      src="/static/c41c656219d120e7127cf400171a9d84/4c91a/players.png"
      key={location.search || "primary"}
    >
      <ContentBox fullWidth>
        <Seo title="World Cup Poule 2022" />
        {!primaryView && !!profile && (
          <StyledHeader>
            <ProfileSection>
              {profile.photoURL && (
                <img
                  src={profile.photoURL}
                  alt={profile.name}
                  referrerPolicy="no-referrer"
                />
              )}
              <h1>{profile.name}</h1>
            </ProfileSection>
            <PointsScored>
              <span>{profile.totalPoints || 0}</span>
              <FormattedMessage id="wc2022_points" />
            </PointsScored>
          </StyledHeader>
        )}

        {user ? (
          <>
            {primaryView && (
              <StyledHeader>
                <Link to="../">⚽️</Link>
                {" | "}
                <h1>
                  <FormattedMessage id="wc2022_predictions" />
                  {filter ? (
                    <>
                      {", filter: "}
                      {filter.length === 1 ? (
                        <>
                          <FormattedMessage id="wc2022_group" />
                          {` ${filter}`}
                        </>
                      ) : (
                        <FormattedMessage
                          id={`wc2022_${filter.toLowerCase().split(" ")[0]}`}
                        />
                      )}
                      <Link to="./">❌</Link>
                    </>
                  ) : (
                    ""
                  )}
                </h1>

                <PointsScored>
                  <span>{profile.totalPoints || 0}</span>
                  <FormattedMessage id="wc2022_points" />
                </PointsScored>
              </StyledHeader>
            )}
            {/* <span>
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  setShowOldMatches(!showOldMatches);
                }}
              >
                {showOldMatches ? (
                  <FormattedMessage id="wc2022_hide_old_matches" />
                ) : (
                  <FormattedMessage id="wc2022_show_old_matches" />
                )}
              </a>
            </span> */}
            <StyledResults>
              {schedule
                .filter(match => {
                  if (!filter || !isNaN(Number(filter))) return match;
                  if (filter.length === 1) {
                    return match.Group === filter;
                  } else {
                    return (
                      match.HomeTeam === filter || match.AwayTeam === filter
                    );
                  }
                })
                // UNCOMMENT THIS FOR EURO 2024
                // .filter(match =>
                //   showOldMatches ? match : match.DateUtc.seconds > yesterday
                // )
                .map((match, idx) => {
                  const prediction = predictions[match.MatchNumber] || {};
                  if (match.RoundNumber !== currentRound) {
                    currentRound = match.RoundNumber;
                    return (
                      <>
                        <h4>
                          <FormattedMessage
                            id={`wc2022_round${match.RoundNumber}`}
                          />
                        </h4>
                        <ScheduleMatch
                          match={match}
                          key={idx}
                          prediction={prediction}
                          primaryView={primaryView}
                          location={location}
                          fullMatchView={false}
                        />
                      </>
                    );
                  } else {
                    return (
                      <ScheduleMatch
                        match={match}
                        idx={idx}
                        key={idx}
                        filter={filter}
                        prediction={prediction}
                        primaryView={primaryView}
                        location={location}
                      />
                    );
                  }
                })}
            </StyledResults>
          </>
        ) : (
          <LoginFirst />
        )}
      </ContentBox>
    </ContentBg>
  );
};

export default Schedule;
